import { lowerCasedReservedFields } from "@/api-client/generic-api/generic-api-variables-parser";
import { BForm } from "@/lib/typed-bootstrap";
import {
  BaseFieldTypeEnum,
  ComplexFieldTypeEnum,
  DateFieldTypeEnum,
  IApiState,
  IFieldState,
  ListFieldTypeEnum,
} from "@/models";
import { fieldFormatter } from "@/utils";
import _cloneDeep from "lodash/cloneDeep";
import ShortUniqueId from "short-unique-id";
import Component from "vue-class-component";
import { Emit, Prop, Vue, Watch } from "vue-property-decorator";
import * as tsx from "vue-tsx-support";
import ApiTypesUpdater from "../object-type/ApiTypesUpdater.mixin";
import {
  IFormSelectGroupState,
  ObjectFieldTypeSelect,
  ObjectFieldTypeSelectOption,
} from "../object-type/ObjectTypeField.component";
import { Button, ButtonThemeEnum } from "../shared/Button.component";
import { InputSizeEnum } from "../shared/Input.component";
import InputLabel from "../shared/InputLabelWrapper.component";
import LabeledTextInput from "../shared/LabeledTextInput.component";
import { Loader } from "../shared/Loader.component";
import Modal from "../shared/Modal.component";
import styles from "./ApiDataObjectTypeFieldForm.component.module.scss";

const { randomUUID } = new ShortUniqueId({ length: 6 });
@Component
export class ApiDataObjectTypeFieldForm extends ApiTypesUpdater {
  _tsx!: tsx.DeclareProps<{
    activeObjectTypeName: ApiDataObjectTypeFieldForm["activeObjectTypeName"];
    apiState: ApiDataObjectTypeFieldForm["apiState"];
    fieldName?: ApiDataObjectTypeFieldForm["fieldName"];
  }> &
    tsx.DeclareOnEvents<{
      onCancel: void;
    }>;

  @Prop({ default: () => [] }) apiState: IApiState;
  @Prop({ required: true }) activeObjectTypeName: string;
  @Prop() fieldName?: string;
  @Emit("cancel") private onCancel() {}

  private errorMessage: string = "";
  private fieldIndex: number = 0;

  private get activeObjectType() {
    return this.apiState.types.find(
      (type) => type.name === this.activeObjectTypeName
    );
  }

  private fields: IFieldState[] = [];

  @Watch("fieldName", { immediate: true }) onFieldNameChange() {
    if (this.fieldName !== undefined) {
      this.fieldIndex =
        this.activeObjectType?.fields.findIndex(
          (field) => field.name === this.fieldName
        ) ?? 0;
      this.fields = [...(this.activeObjectType?.fields ?? [])];
      return;
    }

    const latestNewFieldX = (this.activeObjectType?.fields ?? []).reduce(
      (top, type) => {
        if (type.name.startsWith("newField")) {
          const num = parseInt(type.name.replace("newField", ""), 10);
          if (num > top) {
            return num;
          }
        }

        return top;
      },
      0
    );

    this.fields = [
      ...(this.activeObjectType?.fields ?? []),
      {
        id: randomUUID(),
        name: `newField${latestNewFieldX + 1}`,
        type: BaseFieldTypeEnum.String,
      },
    ];
    this.fieldIndex = this.fields.length - 1;
  }

  private handleNameInput(fieldName: string) {
    this.errorMessage = "";

    if (fieldName.length <= 1) {
      this.errorMessage = this.$t("form_feedback_name_minlength", {
        name: fieldName,
      }).toString();
    } else {
      Vue.set(this.fields, this.fieldIndex, {
        ...this.fields[this.fieldIndex],
        type: this.fields[this.fieldIndex]?.type ?? BaseFieldTypeEnum.String,
        name: fieldName,
      });
      const isDuplicate = this.activeObjectType?.fields.some(
        (field) => field.name === fieldName
      );
      if (isDuplicate) {
        this.errorMessage = this.$t("form_feedback_field_name", {
          name: fieldName,
        }).toString();
      }
      const isReservedFieldName =
        lowerCasedReservedFields[fieldName.trim().toLowerCase()];
      if (isReservedFieldName) {
        this.errorMessage = this.$t("form_feedback_reserved_field_name", {
          name: fieldName,
        }).toString();
      }
    }
  }

  private handleTypeInput(type: string) {
    Vue.set(this.fields, this.fieldIndex, {
      ...this.fields[this.fieldIndex],
      type,
    });
  }

  private async handleSubmit(ev: Event) {
    ev.preventDefault();
    const newState = _cloneDeep(this.apiState);
    const activeObjectType = newState.types.find(
      (type) => type.name === this.activeObjectTypeName
    );
    if (!activeObjectType) {
      return;
    }
    Vue.set(activeObjectType, "fields", this.fields);

    await this.updateApiDeclarationFromState(newState);
    if (!this.serverSideErrors) {
      this.onCancel();
      this.apiState.types = newState.types;
    }
  }

  private get enumTypesOptions(): { value: string; text: string }[] {
    return (this.apiState.enums ?? []).map((type) => ({
      value: type.name,
      text: type.name,
    }));
  }

  private get typeOptions(): IFormSelectGroupState[] {
    return [
      {
        label: this.$t("model_field_section_enums").toString(),
        options: [
          {
            value: "add_enum_type",
            text: this.$t("enum_widget_add_type").toString(),
          },
          ...this.enumTypesOptions,
        ],
      },
      {
        label: this.$t("model_field_section_base").toString(),
        options: [
          { value: BaseFieldTypeEnum.ID, text: "ID" },
          { value: BaseFieldTypeEnum.Boolean, text: "Boolean" },
          { value: BaseFieldTypeEnum.Float, text: "Decimal number (Float)" },
          { value: BaseFieldTypeEnum.Integer, text: "Whole number (Int)" },
          { value: BaseFieldTypeEnum.String, text: "Text" },
        ],
      },
      {
        label: this.$t("model_field_section_base_list").toString(),
        options: [
          { value: ListFieldTypeEnum.ListBoolean, text: "List of Booleans" },
          {
            value: ListFieldTypeEnum.ListFloat,
            text: "List of decimal numbers",
          },
          { value: ListFieldTypeEnum.ListId, text: "List of IDs" },
          {
            value: ListFieldTypeEnum.ListInteger,
            text: "List of whole numbers",
          },
          { value: ListFieldTypeEnum.ListString, text: "List of tags" },
        ],
      },
      {
        label: this.$t("model_field_section_complex").toString(),
        options: [
          { value: ComplexFieldTypeEnum.Email, text: "Email" },
          { value: ComplexFieldTypeEnum.IpAddress, text: "IpAddress" },
          { value: ComplexFieldTypeEnum.Json, text: "Json" },
          { value: ComplexFieldTypeEnum.Url, text: "Url" },
        ],
      },
      {
        label: this.$t("model_field_section_datetime").toString(),
        options: [
          { value: DateFieldTypeEnum.Date, text: "Date" },
          { value: DateFieldTypeEnum.DateTime, text: "DateTime" },
          { value: DateFieldTypeEnum.Time, text: "Time" },
          { value: DateFieldTypeEnum.Timestamp, text: "Timestamp" },
        ],
      },
    ];
  }

  private get fieldTypeSelectId() {
    return `fieldType${this.activeObjectType?.id}`;
  }

  private get showErrors() {
    return !!this.errorMessage;
  }

  private inputFieldFormatter(value: string): string {
    return fieldFormatter(value);
  }

  render() {
    return (
      <BForm
        onSubmit={this.handleSubmit}
        role="form"
        novalidate
        class={styles.form}
      >
        <LabeledTextInput
          id="addModelInput"
          labelText={this.$t("form_input_label_field").toString()}
          value={this.fields[this.fieldIndex].name}
          onInput={this.handleNameInput}
          inputSize={InputSizeEnum.md}
          autoFocus
          selectAllInitially
          formatter={this.inputFieldFormatter}
        />
        <span class={styles.error}>
          {this.showErrors ? this.errorMessage : "\u00A0"}
        </span>
        <InputLabel
          labelText={this.$t("form_input_label_field_type").toString()}
          id={this.fieldTypeSelectId}
          class={styles.fieldTypeSelectWrapper}
        >
          <ObjectFieldTypeSelect
            id={this.fieldTypeSelectId}
            class="default-select__form-input"
            value={this.fields[this.fieldIndex].type}
            options={this.typeOptions}
            onInput={this.handleTypeInput}
          >
            <template slot="first">
              <ObjectFieldTypeSelectOption value={null} disabled>
                {this.$t("model_field_type_placeholder")}
              </ObjectFieldTypeSelectOption>
            </template>
          </ObjectFieldTypeSelect>
        </InputLabel>
        <Modal.ActionButtons class={[styles.actionButtons]}>
          <Button
            onClick={this.onCancel}
            class={styles.button}
            theme={ButtonThemeEnum.naked}
            type="button"
          >
            {this.$t("global_cta_cancel")}
          </Button>
          <Button
            theme={ButtonThemeEnum.primary}
            class={styles.button}
            data-testid="uploadCloudFunction"
            type="submit"
            disabled={this.showErrors || !this.fields[this.fieldIndex]}
          >
            {this.isLoading ? (
              <Loader />
            ) : this.fieldName !== undefined ? (
              this.$t("global_cta_save")
            ) : (
              this.$t("global_cta_create")
            )}
          </Button>
        </Modal.ActionButtons>
      </BForm>
    );
  }
}
